import { object, string } from "yup";
const addressSchema = object({
    address_1: string(),
    address_2: string(),
    city: string(),
    state: string(),
    country: string(),
    postcode: string(),
    company: string(),
});
export default addressSchema;
